<template>
  <Header title="Detail Tagihan Siswa" link1="Tagihan" :link2="user.name"></Header>

  <div class="row gx-2">
    <div class="col-md-12">
      <div class="card h-100">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3" style="padding-bottom:20px;text-align:center;">
              <div class="photo-circle" style="max-width:190px;">
                <img @error="onImageLoadFailure($event)" :src="imagepath" alt="">
              </div>
              <!-- <div class="btn btn-circle btn-gradient mt-3">Kirim Pesan</div> -->
            </div>
            <div class="col-md-8">
              <table class="table table-borderless table-detail">
                <tr><th>Nama</th><th>:</th><td>{{ user.name }}</td></tr>
                <tr><th>NIS</th><th>:</th><td> <a href="javascript:void(0)" class="no-pointer">{{ user.student_profile_nis}}</a> </td></tr>
                <tr><th>Sekolah</th><th>:</th><td>{{ school.name }}</td></tr>
                <tr><th>Kelas</th><th>:</th><td>{{ user.class_name}}</td></tr>
                <tr><th>Ayah</th><th>:</th><td>{{ user.parent_father}}</td></tr>
                <tr><th>Ibu</th><th>:</th><td>{{ user.parent_mother}}</td></tr>
                <tr><th>Telepon</th><th>:</th><td>{{ user.user_profile_no_hp }}</td></tr>
              </table>
            </div>
          </div>
          <div class="position-absolute top-0 end-0 p-2">
            <!-- <a href="#" class="btn-sm btn-link-gray">
              <ion-icon name="pencil"></ion-icon>
            </a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row gx-2 mt-2">
    <div class="col-md-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="m-4">
            <h5 class="text-secondary fw-bold">Total Tagihan</h5>
            <h4 class="d-inline text-primary fw-bold">Rp{{ toLocaleString(stats.total_bill) }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <router-link :to="{ name: 'Payment', query: { child_user_id: user.user_id }}">
        <div class="card card-link h-100">
          <div class="card-body">
            <div class="m-4">
              <h5 class="text-secondary fw-bold">Total Terbayar</h5>
              <h4 class="d-inline text-success fw-bold">
                Rp{{ toLocaleString(stats.total_paid) }}
                <router-link :to="{ name: 'Payment', query: { child_user_id: user.user_id }}" class="btn btn-link-gray">
                  <ion-icon name="arrow-forward-circle-outline"></ion-icon>
                </router-link>
              </h4>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-md-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="m-4">
            <h5 class="text-secondary fw-bold">Sisa Pembayaran</h5>
            <h4 class="d-inline text-danger fw-bold">
              Rp{{ toLocaleString(totalUnpaid) }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <button @click="$router.go(-1)" class="btn btn-light rounded-pill"> <ion-icon name="arrow-back-outline"></ion-icon> </button>
              <h4 class="text-secondary d-inline">Kembali</h4>
              <!-- <router-link to="/bill" tag="a" class="btn btn-circle btn-gradient"><ion-icon name=pencil></ion-icon> Atur Tagihan</router-link> -->
            </div>
            <div class="col-md-8">
              <div class="float-end">
                <!-- <label>Showing 1 - 20 of 305 results</label>
                <nav class="nav-bullet inline" aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                  </ul>
                </nav>
                <div class="v-divider"></div> -->
                <div class="d-inline">
                  <label for="">Tahun Ajar:</label>
                  <select @change="loadData()" v-model="param.school_year_id" class="d-inline text-primary form-select form-select-noborder">
                    <option
                      v-for="sy in schoolYears"
                      :key="sy.school_year_id"
                      :label="sy.name"
                      :value="sy.school_year_id">
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th>No</th>
                <th>Nama Tagihan <ion-icon @click="sort('bill_name')" name="swap-vertical-outline"></ion-icon></th>
                <th>Status <ion-icon @click="sort('status')" name="swap-vertical-outline"></ion-icon></th>
                <!-- <th>Bulan Bayar <ion-icon @click="sort('bill_month')" name="swap-vertical-outline"></ion-icon></th> -->
                <th>Batas <ion-icon @click="sort('deadline_date')" name="swap-vertical-outline"></ion-icon></th>
                <th class="text-end">Total Tagihan <ion-icon @click="sort('total_amount')" name="swap-vertical-outline"></ion-icon></th>
                <th class="text-end">Lunas <ion-icon @click="sort('total_paid')" name="swap-vertical-outline"></ion-icon></th>
                <!-- <th class="text-end">Kurang Bayar <ion-icon @click="sort('total_unpaid')" name="swap-vertical-outline"></ion-icon></th> -->
                <th>Terpublikasi</th>
                <th>Opsi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(detail, i) in sortedUserBills" :key="detail.id">
                <td>{{ i + 1 }}</td>
                <td>{{ detail.bill.name }} <span class="badge bg-info">{{ getMonth(detail.id)}}</span></td>
                <td>
                  <div v-if="detail.status == constants.BILL_PAID" class="btn btn-sm btn-outline-success rounded-pill no-pointer">LUNAS</div>
                  <div v-if="detail.status == constants.BILL_OVERPAID" class="btn btn-sm btn-outline-success rounded-pill no-pointer">KELEBIHAN</div>
                  <div v-if="detail.status == constants.BILL_PENDING" class="btn btn-sm btn-outline-warning rounded-pill no-pointer">AKTIF</div>
                  <div v-if="detail.status == constants.BILL_UNPAID" class="btn btn-sm btn-outline-warning rounded-pill no-pointer">BELUM BAYAR</div>
                </td>
                <!-- <td> <span class="badge bg-info">{{ getMonth(detail.id)}}</span></td> -->
                <td>{{ detail.deadline_date }}</td>
                <td class="text-end text-primary">Rp{{ toLocaleString(detail.total_payment) }}</td>
                <td class="text-end text-success">Rp{{ toLocaleString(detail.total_paid) }}</td>
                <!-- <td class="text-end">Rp{{ toLocaleString(detail.total_unpaid) }}</td> -->
                <td>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" :checked="detail.is_published == 1" @click="publishByID(detail, $event)">
                  </div>
                </td>
                <td>
                  <button v-if="detail.status == constants.BILL_PENDING || detail.status == constants.BILL_UNPAID" type="button" class="btn btn-sm btn-link-gray" @click="edit('edit', detail.spp_bill_id, detail.id)" data-bs-toggle="modal" data-bs-target="#billUserModal"><ion-icon name="create-outline"></ion-icon></button>
                  <button v-if="detail.status == constants.BILL_PAID || detail.status == constants.BILL_OVERPAID" type="button" class="btn btn-sm btn-link-gray" @click="edit('detail', detail.spp_bill_id, detail.id)" data-bs-toggle="modal" data-bs-target="#billUserModal"><ion-icon name="create-outline"></ion-icon></button>
                  <!-- <button type="button" class="btn btn-sm btn-link-gray" data-bs-toggle="tooltip" data-bs-placement="top" title="Cetak"><ion-icon name="print-outline"></ion-icon></button> -->
                  <!-- <button type="button" class="btn btn-sm btn-link-gray" data-bs-toggle="tooltip" data-bs-placement="top" title="Hapus"><ion-icon name="trash-outline"></ion-icon></button> -->
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row">
            <div class="col-12">
              <div class="legend legend-info"></div> Terpublish
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <edit-user-bill :data="data" :math_operations="math_operations" @loadData="loadData"></edit-user-bill>

</template>

<script language="ts" src="./scripts/bill-detail.ts"></script>
